
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    mixins: [ScreenSize],
    data() {
      return {
        advertisingSpace: 'WERBEFLÄCHE',
      };
    },
  };
